.book-details
    margin-top: 2rem
    margin-bottom: 2rem
    .container
        @media ( max-width: 991px )
            flex-direction: row
        @media ( max-width: 768px )
            flex-direction: column !important
        .img-book
            width: 25%
            margin-right: 1rem
            @media ( max-width: 991px )
                width: 37%
            @media ( max-width: 768px )
                width: 100%
                margin-bottom: 2rem
                justify-content: center
            figure
                max-width: 300px !important
            div
                text-align: center
                .buttons
                    display: flex
                    justify-content: center
                    width: 100%
                    button
                        margin: 0.2rem
                        padding: 0.5rem
                        border: 1px solid gray
                        border-radius: 5px
                        outline: none
                        color: #8f8f8f
                        &:nth-of-type(2)
                            background-color: #f3911e
                            color: white
                            font-weight: 600
                        i
                            transition: all 0.5s ease-in-out
                    .heart-color.active
                        color: #f3911e !important
                        transition: all 0.5s ease-in-out
                .price
                    display: flex
                    justify-content: center 
                    p
                        &:first-of-type
                            color: red
                            text-decoration: none !important
                        &:last-of-type
                            color: gray
                            font-size: 13px
                            text-decoration: line-through
                            margin-left: 0.5rem
        .book-details-child
            width: 75%
            @media ( max-width: 768px )
                width: 100%
            p
                color: gray
                span
                    color: #f3911e
                    font-weight: 600
            h5
                color: #f3911e
                span
                    text-decoration: line-through
                    font-size: 12px
                    color: black
                    margin: 0 0.5rem
            .count-up
                display: flex
                align-items: center
                & > div
                    margin-left: 1rem 
                    display: flex
                    border: 1px solid gray
                    width: 60px
                    justify-content: space-between
                    // padding: 0 0 0 2rem !important
                    p
                        width: 50%
                        text-align: center
                        line-height: 32px
                    div
                        display: flex
                        flex-direction: column
                        button
                            justify-self: flex-end
                            padding:0  0.5rem
                            border: none
                            outline: none
                            color: white
                            &:first-of-type
                                background: #f3911e !important
                            &:last-of-type
                                background: gray !important

.book-details-AR
    @extend .book-details
    & > *
        text-align: right
    .container
        flex-direction: row-reverse 
        .img-book
            margin-right: 0
            margin-left: 1rem
            @media ( max-width: 500px )
                margin-left: 0 !important
        .book-details-child
            .count-up
                justify-content: flex-end

.modal_Book_container
    display: flex
    @media screen and ( max-width: 991px )
        flex-direction: column !important
    & > div
            margin: 0.5rem
            img
                max-width: 300px
                @media screen and ( max-width: 991px )
                    width: 100%
                    max-width: none 
