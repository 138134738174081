.shopping-items-order
    margin-top: 2rem
    .container 
        display: flex
        justify-content: space-between
        @media ( max-width: 1024px )
            flex-direction: column
        .shopping-item
            width: 100%
            margin: 0.5rem
            @media ( max-width: 575px )
                margin: 0rem !important
            @media ( max-width: 1024px )
                width: 100%
            div
                background-color: #eeeeee
                padding: 1rem
                .p
                    border-top: 1px solid gray
                    padding-top: 0.5rem
                    font-size: 12px 
                & > h4
                    border-bottom: 1px solid gray
                    text-align: right
                    font-weight: 600
                    color: #444444
                    padding-bottom: 0.5rem
                & > div
                    display: flex
                    align-items: flex-start
                    i
                        margin-right: 1rem
                        cursor: pointer
                        align-self: center
                    .items-info
                        display: flex
                        flex-direction: column
                        .count-up
                            display: flex
                            align-items: center
                            padding-left: 0 !important
                            & > div
                                margin-left: 1rem 
                                display: flex
                                border: 1px solid gray
                                width: 60px
                                padding-top: 0 !important
                                padding-bottom: 0 !important
                                justify-content: space-between
                                // padding: 0 0 0 2rem !important
                                padding-left: 10px !important 
                                p
                                    width: 50%
                                    text-align: center
                                    line-height: 32px
                                div
                                    display: flex
                                    flex-direction: column
                                    padding-top: 0 !important
                                    padding-bottom: 0 !important
                                    padding-right: 0 !important
                                    padding-left: 12px !important
                                    button
                                        justify-self: flex-end
                                        width: 100%
                                        padding: 0 0.5rem !important
                                        border: none
                                        outline: none
                                        color: white
                                        &:first-of-type
                                            background: #f3911e !important
                                        &:last-of-type
                                            background: gray !important
                    h5
                        flex-grow: 1
                        text-align: right
                        align-self: flex-start
                        color: #f3911e
                        span
                            color: black
                            font-size: 12px
                            text-decoration: line-through   
                            margin: 0 0.2rem
                        @media ( max-width: 767px )
                            margin-left: -83px !important
                            margin-top: -20px !important
        .order
                h5
                    margin: 1.5rem 0
                table
                    width: 100%
                    th
                        box-shadow: 0px 0px 4px gray inset
                        padding: 1rem
                    td
                        box-shadow: 0px 0px 4px gray inset
                        padding: 1rem
        .acc-address
            margin-top: 2rem
            div
                h4
                    margin-bottom: 1.5rem
                    b
                        color: #5f5f5f
                .acc-address-order
                    position: relative
                    background-color: #dddddd
                    &::after
                        content: ''
                        position: absolute
                        width: 15px 
                        height: 15px
                        top: -14px
                        left: 15px 
                        background-color: #dddddd
                        clip-path: polygon(50% 0%, 0% 100%, 100% 100%)
                    .add-new-address
                        // background-color: white
                        form
                            & > input
                                    width: 48%
                                    padding: 1rem
                                    margin: 1%
                                    border: none
                                    outline: none 
                                    transition: all 0.5s ease-in-out
                                    box-shadow: 0px 0px 4px gray inset
                                    border-radius: 5px
                                    &:nth-child(5)
                                        width: 98%
                                    &:nth-child(6)
                                        width: 98%
                                    &:focus
                                        transition: all 0.5s ease-in-out
                                        box-shadow: 0px 0px 10px #f3911e
                                    @media ( max-width: 991px )
                                        width: 98%
                        h5
                            margin-left: 1rem
                            color: #5f5f5f
        form
            .safe-buy
                margin-top: 2rem
                background-color: #dddddd
                padding: 1rem 
                .container
                    display: flex
                    flex-direction: column
                    input[type="submit"]
                        background-color: white
                        border: 2px solid #f3911e
                        outline: none
                        margin-top: 1rem
                        padding: 0.2rem 1rem
                        color: #f3911e
                        border-radius: 10px
                        max-width: 120px
                    input[type="checkbox"]
                        margin: 0 0.5rem
                        display: inline
                    label
                        display: inline
                    & > div
                            &:first-of-type
                                background-color: white
                                padding: 0.5rem
                                margin: 0.5rem 0
                                position: relative
                                &::before
                                    content: ''
                                    position: absolute
                                    width: 10px 
                                    height: 10px
                                    top: -10px
                                    left: 10px 
                                    background-color: white
                                    clip-path: polygon(50% 0%, 0% 100%, 100% 100%)

.shopping-items-order-AR
    text-align: right
    @extend .shopping-items-order
    .container
        flex-direction: row-reverse
        @media ( max-width: 1024px )
            flex-direction: column
        .shopping-item
            div
                div
                    flex-direction: row-reverse
                    align-items: flex-end
                    @media ( max-width: 500px )
                        align-items: center
                    i
                        margin-left: 1rem
                    h5
                        text-align: left
                        margin-right: -94px !important
                        margin-left: 0px !important
                    .p
                        text-align: right
                    .items-info
                        .count-up
                            flex-direction: row
                            div
                                div
                                    padding-right: 8px !important
                                    padding-left: 1px !important
        .order
            text-align: right
        form
            .safe-buy
                .container
                    & > div
                            &:first-of-type
                                &::before
                                    left: auto
                                    right: 10px !important 
                            &:last-of-type
                                display: flex
                                align-items: center 
                                flex-direction: row-reverse
                    input[type="submit"]
                        margin-left: auto
        .acc-address
            margin-top: 2rem
            div
                h4
                    margin-bottom: 1.5rem
                    b
                        color: #5f5f5f
                .acc-address-order
                    position: relative
                    background-color: #dddddd
                    &::after
                        content: ''
                        position: absolute
                        width: 15px 
                        height: 15px
                        top: -14px
                        right: 15px
                        left: auto 
                        background-color: #dddddd
                        clip-path: polygon(50% 0%, 0% 100%, 100% 100%)
                    .add-new-address
                        // background-color: white
                        form
                            & > input
                                    direction: rtl
                                    @media ( max-width: 991px )
                                        width: 98%
                        h5
                            margin-right: 1rem
                            margin-left: 0rem !important
                            color: #5f5f5f
                    #panel1a-header
                        flex-direction: row-reverse
                        div
                            flex-direction: row-reverse
