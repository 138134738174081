.create-account
    .container
        form
            h5
                margin: 2rem 0
                background-color: #dddddd
                padding: 1rem
                font-weight: 400   
            p
                max-width: 70%
                margin: 1rem
            b
                margin: 1rem
                a
                    color: #f3911e
            & > input
                    width: 48%
                    padding: 1rem
                    margin: 1%
                    border: none
                    outline: none 
                    transition: all 0.5s ease-in-out
                    box-shadow: 0px 0px 4px gray inset
                    border-radius: 5px
                    &:focus
                        transition: all 0.5s ease-in-out
                        box-shadow: 0px 0px 10px #f3911e
                    @media ( max-width: 991px )
                        width: 98%
                    &[type='submit']
                        background-color: white
                        border-radius: 10px
                        box-shadow: 0px 0px 10px #f3911e
                        color: #f3911e
                        font-weight: 600
                        @media ( max-width: 600px )
                            margin: 1rem 0

.create-account-AR
    @extend .create-account
    .container
        text-align: right
        direction: rtl
        form
            .already-signin
                display: flex
                // flex-direction: row-reverse
