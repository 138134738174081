.books
    .container
        display: flex
        @media ( max-width: 991px )
            flex-direction: column-reverse
    .search-filter
        width: 30%
        @media ( max-width: 991px )
            width: 100%
        // border: 1px solid black
        label
            color: #f3911e
        div
            &::after
                border-bottom: 2px solid #f3911e !important
        .all-cat
            width: 90%
            border: 1px solid gray
            margin: auto !important
            max-height: 506px !important
            overflow-y: scroll
            div
                padding: 0.5rem 1rem !important
                display: flex !important
                justify-content: space-between
                align-items: cneter 
                cursor: pointer
                i
                    color: #f3911e
                p
                    margin-bottom: 0
                &:hover
                    background-color: #d8d8d8
            .focus-bg
                background-color: #d8d8d8
        .best-sellers
            width: 90%
            margin: auto
            margin-top: 3rem
            h4
                background-color: #d8d8d8
                // padding: 1rem 0
                border-bottom: 5px solid #f3911e
                font-size: 20px
                margin-bottom: 2rem
                i
                    color: white
                    background-color: #f3911e
                    padding: 0.5rem 1rem
                    margin-right: 0.5rem
            div
                display: flex
                margin: 0.5rem
                div
                    display: flex
                    flex-direction: column
                    p
                        color: #f3911e
                        font-weight: 600
                    span
                        color: black
                        font-weight: 700
                img
                    height: 100px
                    padding: 0rem 1.5rem
                    object-fit: contain
    .all-books
        width: 70%
        background: white
        // border: 1px solid black
        @media  (max-width: 991px)
            width: 100% !important
        article
            header
                width: 100%
                & > div
                    display: flex
                    justify-content: space-between
                    align-items: center
                    border: 1px solid gray
                    margin: 5px
                    // padding: 0.3rem
                    flex-wrap: wrap
                    height: 40px
                    @media screen and (max-width: 991px )
                        height: 140px
                .sort
                    display: flex
                    align-items: center
                    justify-content: center
                    margin-right: auto
                    margin-left: auto
                    p
                        margin: 0 0.5rem
                        margin-bottom: 0 
                    select
                        padding: 0.2rem
                        @media ( max-width: 991px )
                            margin: 1rem 0
                .pages-num
                    @media ( max-width: 991px )
                        margin-left: auto
                        margin-right: auto
                .show-display
                    display: flex
                    button
                        padding: 0 0.5rem
                        margin: 0 0.2rem
                        background-color: transparent
                        border: 1px solid gray
                        color: gray
                        width: 100%
                        &.active
                            background: #f3911e
                            i
                                color: white
            section
                display: flex
                flex-wrap: wrap
                justify-content: center
                @media ( max-width: 991px )
                    justify-content: center

.mySwiper-hero-AR
    width: 100% !important
    height: 500px !important
    .swiper-slide
        flex-direction: row-reverse !important
        & > *
            text-align: right !important
        img
            margin-left: 8rem 
            margin-right: 0rem
        div
            .line
                margin-left: auto !important
        @media ( max-width: 633px )
            flex-direction: column !important
            justify-content: center


.books-AR
    @extend .books
    .container
        flex-direction: row-reverse
        @media ( max-width: 991px )
            flex-direction: column-reverse
        .search-filter
            text-align: right !important
            label
                text-align: right !important
                margin-right: auto !important
                width: 100% !important
                color: #f3911e
                transform: scale(1)
            input
                text-align: right !important
            .CATEGORIES
                div
                    display: flex
                    flex-direction: row-reverse
                    line-height: 15px
                    svg
                        margin-right: 0
                        margin-left: 1rem
            .all-cat
                div
                    flex-direction: row-reverse
                    cursor: pointer
                    i
                        transform: scaleX(-1)
            .best-sellers
                h4
                    display: flex
                    flex-direction: row-reverse
                    line-height: 35px
                    i
                        margin-right: 0
                        margin-left: 1rem
                & > div
                    flex-direction: row-reverse !important