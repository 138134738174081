.shopping-items
    margin-top: 2rem
    .container 
        display: flex
        justify-content: space-between
        @media ( max-width: 1024px )
            flex-direction: column
        .shopping-item
            width: 65%
            margin: 0.5rem
            @media ( max-width: 575px )
                margin: 0rem !important
            @media ( max-width: 1024px )
                width: 100%
            div
                background-color: #eeeeee
                padding: 1rem
                .p
                    border-top: 1px solid gray
                    padding-top: 0.5rem
                    font-size: 12px 
                & > h4
                    border-bottom: 1px solid gray
                    text-align: right
                    font-weight: 600
                    color: #444444
                    padding-bottom: 0.5rem
                & > div
                    display: flex
                    align-items: flex-start
                    i
                        margin-right: 1rem
                        cursor: pointer
                        align-self: center
                    .items-info
                        display: flex
                        flex-direction: column
                        .count-up
                            display: flex
                            align-items: center
                            padding-left: 0 !important
                            & > div
                                margin-left: 1rem 
                                display: flex
                                border: 1px solid gray
                                width: 60px
                                padding-top: 0 !important
                                padding-bottom: 0 !important
                                justify-content: space-between
                                // padding: 0 0 0 2rem !important
                                padding-left: 10px !important 
                                p
                                    width: 50%
                                    text-align: center
                                    line-height: 32px
                                div
                                    display: flex
                                    flex-direction: column
                                    padding-top: 0 !important
                                    padding-bottom: 0 !important
                                    padding-right: 0 !important
                                    padding-left: 12px !important
                                    button
                                        justify-self: flex-end
                                        width: 100%
                                        padding: 0 0.5rem !important
                                        border: none
                                        outline: none
                                        color: white
                                        &:first-of-type
                                            background: #f3911e !important
                                        &:last-of-type
                                            background: gray !important
                    h5
                        flex-grow: 1
                        text-align: right
                        align-self: flex-start
                        color: #f3911e
                        span
                            color: black
                            font-size: 12px
                            text-decoration: line-through   
                            margin: 0 0.2rem
                        @media ( max-width: 767px )
                            margin-left: -83px !important
                            margin-top: -20px !important

        .items-total
            width: 35%
            margin: 0.5rem
            @media ( max-width: 575px )
                margin: 0rem !important
            @media ( max-width: 1024px )
                width: 100%
            .total
                background-color: #eeeeee
                margin: 1rem 0
                padding: 2rem
                display: flex
                flex-direction: column 
                p
                    color: black
                    font-weight: 600
                    margin: 1rem 0
                    span
                        color: #f3911e
                .discount-code
                    display: flex
                    margin-bottom: 1rem
                    align-items: flex-end
                    justify-content: flex-start
                    @media ( max-width: 1024px )
                        justify-content: flex-start !important
                    p
                        margin-bottom: 0 !important
                        color: #f3911e
                    input 
                        background-color: transparent 
                        border: none 
                        outline: none
                        border-bottom: 2px solid #f3911e
                        margin-left: 0.5rem
                        padding: 0 !important
                        height: 50%
                button
                    max-width: 170px 
                    justify-self: center
                    align-self: center
                    padding: 0.5rem 0.5rem
                    background-color: #f3911e
                    border: none
                    outline: none
                    border-radius: 5px 
                    color: white
            .suggestions
                background-color: #eeeeee
                padding: 1rem
                display: flex
                align-items: center
                height: auto
                flex-direction: column
                img
                    max-height: 150px
                    margin-right: 0.5rem
                .suggestions-text
                    h2
                        font-size: 17px
                        font-weight: 600
                    p
                        font-size: 11px
                        font-weight: 600
                    h5
                        color: #f3911e
                        font-size: 15px
                        span
                            color: black
                            font-size: 12px
                            margin-left: 0.2rem
                            @media ( max-width: 1199px )
                                font-size: 11px
                        @media ( max-width: 1199px )
                            font-size: 13px
                    div
                        display: flex
                        align-items: center
                        button
                            font-size: 12px
                            background-color: #f3911e
                            color: white
                            border: none
                            outline: none
                            padding: 0.5rem 1rem
                            @media ( max-width: 1399px )
                                padding: 0.2rem 0
                                width: 47px
                    .count-up
                        display: flex
                        align-items: center
                        padding-left: 0 !important
                        margin: 0 0.5rem
                        & > div
                            margin-left: 0.2rem 
                            display: flex
                            border: 1px solid gray
                            width: 49px
                            height: 38px
                            padding-top: 0 !important
                            padding-bottom: 0 !important
                            justify-content: space-between
                            // padding: 0 0 0 2rem !important
                            padding-left: 2px !important 
                            p
                                width: 50%
                                text-align: center
                                line-height: 32px !important
                            div
                                display: flex
                                flex-direction: column
                                padding-top: 0 !important
                                padding-bottom: 0 !important
                                padding-right: 0 !important
                                padding-left: 5px !important
                                button
                                    justify-self: flex-end
                                    width: 100%
                                    padding: 0 0.5rem !important
                                    border: none
                                    outline: none
                                    color: white
                                    &:first-of-type
                                        background: #f3911e !important
                                    &:last-of-type
                                        background: gray !important

.shopping-items-AR
    text-align: right
    @extend .shopping-items
    .container
        flex-direction: row-reverse
        @media ( max-width: 1024px )
            flex-direction: column
        .shopping-item
            div
                div
                    flex-direction: row-reverse
                    align-items: flex-end
                    i
                        margin-left: 1rem
                    h5
                        text-align: left
                        margin-right: -94px !important
                        margin-left: 0px !important
                    .p
                        text-align: right
                    .items-info
                        .count-up
                            flex-direction: row
                            div
                                div
                                    padding-right: 8px !important
                                    padding-left: 1px !important

        .items-total
            .total
                .discount-code
                    flex-direction: row-reverse
                    input
                        text-align: right
                        margin-right: 0.5rem
                        margin-left: 0 !important
            .suggestions
                img
                    margin-left: 0.5rem
                    margin-right: 0
                & > div 
                    flex-direction: row-reverse !important
                .suggestions-text
                    & > div
                            flex-direction: row-reverse !important
