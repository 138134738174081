.footer
    background-color: #c7c7c759 !important
    .container
        .footer-1
            padding: 1rem 0
            // display: flex
            // justify-content: space-between
            flex-wrap: wrap
            div
                display: flex
                justify-content: space-between
                align-items: center
                flex-wrap: wrap
            ul
                list-style: none !important
                margin-top: 2rem
                li
                    a
                        color: black
                        text-decoration: none
                        i
                            font-size: 10px !important
                &:last-of-type
                    li
                        a
                            i
                                color: #f3911e !important
                                font-size: 15px !important
    .footer-2
        background-color: #dddddd !important
        padding: 2rem 0
        .container
            display: flex
            align-items: center
            @media ( max-width: 991px )
                flex-wrap: wrap
            & > img
                height: 100px
            div
                display: flex
                justify-content: space-between
                align-items: center
                padding: 1rem
                text-align: center
                @media ( max-width: 991px )
                    flex-wrap: wrap
                    justify-content: center
                i
                    color: gray
                    font-size: 2rem
                    margin-bottom: 1rem
                div
                    display: flex
                    flex-direction: column
    .footer-3
        padding: 1rem 0
        .container
            display: flex
            justify-content: center
            flex-direction: column
            & > div
                // border-top: 1px solid gray !important
                padding-top: 1rem
                display: flex
                justify-content: space-between
                @media (max-width: 991px)
                    flex-wrap: wrap
                div
                    display: flex
                    &:first-of-type
                        margin-right: 1rem
                    i
                        font-size: 2rem
                        padding: 0 0.2rem
                        color: gray

.footer-AR
    @extend .footer
    .container
        .footer-1
            flex-direction: row-reverse !important
            text-align: right
            ul
                margin-left: auto
                li
                    a
                        display: flex
                        align-items: center
                        flex-direction: row-reverse !important
                        i
                            transform: scaleX(-1)
                            margin-left: 0.5rem
    .footer-2
        .container
            flex-direction: row-reverse !important
    .footer-3
        .container
            text-align: right
            div
                flex-direction: row-reverse
                div
                    div
                        &:first-of-type
                            margin-left: 1rem !important

.payment-methods
    margin-top: 1rem
    i
        font-size: 35px
        margin: 0.2rem
