.provada-store-about
    .about-first-section
        margin-top: 5rem
        @media ( max-width: 767px )
            margin-top: 2rem
        .container
            display: flex
            justify-content: space-between
            align-items: center
            @media ( max-width: 1024px )
                flex-direction: column
            h3
                max-width: 500px
                font-weight: 400
                margin: 2rem
                @media ( max-width: 767px )
                    margin: 1rem 0 !important
            div
                max-width: 500px
                img
                    width: 100%
                    height: 300px
                    border-radius: 10px
                    box-shadow: 0 0 10px gray
                    object-fit: cover

    .about-video
        margin-top: 10rem
        background-color: #f9cb8f
        height: 350px
        .container
            display: flex
            justify-content: center
            div
                height: 400px
                width: 60%
                @media ( max-width: 1024px )
                    width: 100%
                margin-top: -5rem
                border: 10px solid #f9cb8f
                img
                    width: 100%
                    height: 100%
                    object-fit: cover

    .vision-mission-history-about
        margin-top: 10rem
        @media ( max-width: 767px )
            margin-top: 2rem
        .container
            display: flex
            @media ( max-width: 1024px )
                flex-direction: column
            div
                margin: 1rem
                i
                    font-size: 40px
                    margin-bottom: 1rem
                    color: #f3911e


.provada-store-about-AR
    @extend .provada-store-about
    text-align: right
    .about-first-section
        @media ( max-width: 767px )
            margin-top: 2rem
        .container
            flex-direction: row-reverse !important
            @media ( max-width: 1024px )
                flex-direction: column !important
            h3
                @media ( max-width: 767px )
                    margin: 1rem 0 !important
    .vision-mission-history-about
        @media ( max-width: 767px )
            margin-top: 2rem
        .container
            flex-direction: row-reverse
            @media ( max-width: 1024px )
                flex-direction: column
