.btn-outline-success {
  background-color: #f3911e !important;
  color: white !important;
  box-shadow: none !important;
  padding: 0 1rem !important;
  border: none;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f3911e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f3901e9d;
}

.btn-outline-success:hover {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

#dropdown-basic-button {
  background-color: #f3911e !important;
  color: white;
  box-shadow: none;
  border: none;
  margin-left: 0.5rem;
}

.dropdown-item:focus {
  background-color: #f3911e;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  flex-wrap: nowrap !important;
}

.navbar-nav {
  flex-direction: row !important;
  margin: auto !important;
}

@media (max-width: 991px) {
  .second-nav-form {
    order: 3;
    width: 100%;
  }
  .second-nav-container {
    flex-wrap: wrap !important;
  }
}

@media (max-width: 767px) {
  .navbar-nav.navbar-nav-scroll {
    font-size: 12px !important;
  }
  .navbar-brand img {
    max-width: 110px !important;
  }
  .second-nav-form button:first-of-type {
    font-size: 9px !important;
  }
}

.main-nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  flex-wrap: wrap !important;
}

@media (max-width: 607px) {
  .second-nav .nav-links-my a {
    margin: 0 0.7rem !important;
  }
}

@media (max-width: 547px) {
  .second-nav .nav-links-my a {
    margin: 0 0.5rem !important;
  }
}

@media (max-width: 508px) {
  .second-nav .nav-links-my {
    width: 100% !important;
  }
}

@media (max-width: 405px) {
  .navbar-nav.navbar-nav-scroll {
    font-size: 11px !important;
  }
  .second-nav .nav-links-my a {
    margin: 0 0.4rem !important;
  }
}

@media (max-width: 415px) {
  .navbar-brand img {
    max-width: 100px;
  }
  .second-nav .second-nav-container .navbar-brand {
    margin-right: 1rem !important;
  }
  .sign-in button:first-of-type {
    font-size: 14px !important;
  }
  .sign-in button:last-of-type {
    font-size: 12px !important;
  }
  .second-nav .second-nav-container .sign-in {
    width: 54% !important;
  }
}
.navbar-nav .dropdown-menu {
  position: absolute !important;
}

.navbar-nav-scroll {
  overflow-y: visible;
}

.mySwiper-hero {
  width: 100% !important;
  height: 500px !important;
}
.swiper-slide {
  background-image: url("./Assets/images/hero-bg-01.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide div {
  max-width: 350px;
}

.swiper-slide div .line {
  width: 100px;
  height: 3px;
  margin: 1rem 0;
  background-color: #f3911e;
}

.swiper-slide div h1 {
  color: #444444;
  font-size: 47px;
  font-weight: 700;
}

.swiper-slide img {
  margin-right: 8rem;
}

@media (max-width: 768px) {
  .swiper-slide img {
    margin-right: 2rem;
    height: 300px !important;
  }
  .swiper-slide div {
    max-width: 300px;
  }
}

@media (max-width: 548px) {
  .swiper-slide {
    flex-direction: column;
  }
  .swiper-slide img {
    padding-top: 2rem;
  }
}

.MuiButtonBase-root {
  background-color: #f3911e;
}

.css-xn6ruc > :not(style) {
  width: 90% !important;
  margin-bottom: 2rem !important;
  color: #f3911e !important;
}

.CATEGORIES {
  background-color: #f3911e;
  padding: 1rem;
  width: 90%;
  color: white;
  margin: auto;
  margin-top: 1rem;
}

.CATEGORIES div svg {
  margin-right: 0.5rem;
}

.cart-hover {
  position: relative;
}

.cart-hover > div {
  display: none;
  background-color: white;
  /* border: 1px solid gray; */
  width: 380%;
  min-width: 521px;
  max-height: 500px;
  position: absolute;
  right: 0;
  top: 45px;
  z-index: 110;
  padding: 1rem;
  box-shadow: 0 0 10px 2px gray;
  overflow-y: auto !important;
}

.cart-hover > div img {
  height: 100px;
}

.cart-hover:hover > div {
  display: flex;
  flex-direction: column;
}

.cart-hover:hover > div .card-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}

.cart-hover:hover > div .card-holder div {
  width: 25%;
}

.cart-hover:hover > div .card-holder div:nth-child(3) button {
  /* border: 1px solid gray !important; */
  padding: 0rem 0.5rem !important;
  font-size: 18px;
  background-color: #f3911e;
  color: white;
  box-shadow: 0px 0px 10px #f3911e inset;
  width: 28px !important;
  /* height: 30px !important; */
  line-height: 24px;
}

.cart-hover:hover > div .card-holder div:nth-child(3) p {
  margin: 0 0.5rem;
}

.cart-hover:hover > div .card-holder div b {
  text-decoration: underline;
}

.cart-hover:hover > div .card-holder div:last-of-type {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row !important;
}

.cart-hover:hover > div .card-holder div:last-of-type h4 {
  margin-bottom: 0;
  font-size: 15px;
}

@media (max-width: 551px) {
  .cart-hover:hover > div .card-holder div:nth-child(3) button {
    width: 30px !important;
    height: 30px !important;
  }
  .cart-hover > div {
    right: -134px;
  }
  .cart-hover:hover > div .card-holder div:nth-child(3) p {
    margin: 0 !important;
  }
  .cart-hover > div {
    width: 300px;
  }
  .cart-hover > div img {
    width: 100% !important;
    object-fit: cover;
  }
}

@media (max-width: 991px) {
  .cart-hover > div {
    right: -100px;
    min-width: 0 !important;
  }
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #f3911e !important;
}

.Accordion-body .row p {
  text-align: left !important;
}

.arAccordion {
  text-align: right !important;
}

.arAccordion-body .row p {
  text-align: right !important;
}

.accordion-button {
  border-color: #f3911e !important;
  color: #f3911e !important;
  padding: 0.5rem 1.25rem;
}

.accordion-button.collapsed::after {
  background-image: url("../src/Assets/images/plus-solid.svg") !important;
  color: rgb(187, 187, 187) !important;
}

.accordion-button.collapsed::after svg path {
  color: rebeccapurple;
}

.accordion-button::after {
  background-image: url("./Assets/images/minus-solid-white-02.svg") !important;
  color: rgb(187, 187, 187) !important;
}

.accordion-button::after svg path {
  color: red;
}

.accordion-button {
  border: 3px solid #f4911d !important;
  border-bottom: 8px solid #f4911d !important;
  box-shadow: none;
  border-radius: 10px;
}

.accordion-button:focus {
  box-shadow: none !important;
  border-radius: 15px;
}

.accordion-button:not(.collapsed) h4 {
  box-shadow: none !important;
  border-radius: 15px;
  color: white;
  font-weight: 300 !important;
}

.accordion-button {
  /* background-color: #ffffff !important; */
  border-radius: 10px !important;
}

.arAccordion .accordion-button {
  flex-direction: row-reverse;
  justify-content: space-between;
  border-radius: 15px;
}

.arAccordion .accordion-button::after {
  border-radius: 15px;
  margin-left: 0;
}

.accordion-button:focus {
  background-color: #f9cb8f !important;
  border-radius: 10px;
}

.accordion-button {
  background-color: #f9cb8f !important;
  border-radius: 10px !important;
}

.accordion-button:not(.collapsed)::after {
  transform: scale(1.5) rotate(180deg);
}

.accordion-button:not(.collapsed) > * {
  color: white !important;
}

.accordion-button.collapsed {
  border: none !important;
}

.accordion-button.collapsed::after {
  transform: scale(1.5);
}

.accordion-button.collapsed {
  background-color: white !important;
}

.js-image-zoom__zoomed-image {
  z-index: 10 !important;
}

.book-details-AR .js-image-zoom__zoomed-image {
  left: 0 !important;
  transform: translate(-100%) !important;
}

.book-details .js-image-zoom__zoomed-image {
  right: 0 !important;
  transform: translate(60%) !important;
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  z-index: 2000;
  /* padding-top: 100px; Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

.page-title {
  height: 250px;
  background: url("./Assets/images/hero-bg-01.png");
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid gray;
}

.page-title .container {
  height: 100%;
  display: grid;
  place-items: center;
  text-align: center !important;
}

.page-title .container h1 {
  color: #444444;
  font-size: 50px;
  font-weight: 700;
}

.contact-form input {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  /* padding: 10px;*/
  margin: 0 10px;
}

/* contact page */

.contact-form textarea {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  margin: 0 10px;
}

.btn-contact {
  padding: 10px;
  background-color: #eb8c1e;
  color: #ffffff;
  transition: all 0.5s;
  position: relative;
  border-radius: 10px;
}
.btn-contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
}
.btn-contact:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.btn-contact::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid #eb8c1e;
  border-radius: 10px;
  transform: scale(1.2, 1.2);
}
.btn-contact:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

.contact-card-row1 {
  flex-direction: row-reverse;
  margin-bottom: 100px;
}

.contact-card-col1 {
  position: relative;
  display: flex;
  flex-direction: column;
}

.contact-card-row2 {
  background-color: #f9cb8f;
  max-height: 500px !important;
  min-height: 212px !important;
  position: absolute;
  width: 80%;
  bottom: -52px;
  left: 21%;
}

.contact-card-col2 {
  margin-left: -154px;
  margin-top: 18px !important;
}

.contact-card-fluid2 {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-left: -12px !important;
}

@media only screen and (max-width: 1500px) {
  .contact-card-col2 {
    margin-left: -154px;
    width: 60% !important;
    margin-top: 18px !important;
  }
}

@media only screen and (max-width: 1430px) {
  .contact-card-col2 {
    width: 65% !important;
  }

  .contact-card-fluid2 {
    margin-left: 42px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .contact-card-col2 {
    width: 70% !important;
  }

  .contact-card-col2 p {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .contact-card-col2 {
    width: 75% !important;
  }

  .contact-card-fluid2 {
    margin-left: 60px !important;
  }
}

@media only screen and (max-width: 1130px) {
  .contact-card-col2 {
    width: 80% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .contact-card-fluid1 {
    margin-top: 200px;
  }
}

@media only screen and (max-width: 490px) {
  .contact-card-col2 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 375px) {
  .contact-card-fluid2 {
    margin-left: 100px !important;
  }
}

.registration-steps-band {
  margin-top: 50px;
  display: inherit;
  background-color: #f9cb8f;
  padding: 50px 0;
}

.vision-mission-history .container h1 {
  background-color: #f9cb8f;
  color: #2d3339;
  text-align: center;
  border-radius: 30px;
  height: 50px;
  line-height: 50px;
}

.vision-mission-history .container h3 {
  text-align: center;
  margin: 2rem 0;
}

.vision-mission-history .container div {
  display: flex;
  background-color: #f9cb8f;
  align-items: center;
  border-radius: 30px 30px;
}

.vision-mission-history .container div img {
  padding: 2rem;
  border-radius: 50px;
  width: 50%;
  max-width: 50%;
  min-width: 49.5%;
  max-height: 400px;
  min-height: 399px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .vision-mission-history .container div img {
    width: 100% !important;
    border-radius: 25px;
    max-width: 100%;
    padding: 1rem 0;
  }
}

.vision-mission-history-AR .container h1 {
  background-color: #f9cb8f;
  color: #2d3339;
  text-align: center;
  border-radius: 30px;
  height: 50px;
  line-height: 50px;
}

.vision-mission-history-AR .container h3 {
  text-align: center;
  margin: 2rem 0;
}

.vision-mission-history-AR .container div {
  display: flex;
  background-color: #f9cb8f;
  align-items: center;
  border-radius: 30px 30px;
}

.vision-mission-history-AR .container div img {
  padding: 2rem;
  border-radius: 50px;
  width: 50%;
}

@media (max-width: 768px) {
  .vision-mission-history .container div {
    flex-direction: column;
    padding: 0 2rem;
    text-align: center;
  }
  .vision-mission-history .container div img {
    width: 100%;
  }
  .vision-mission-history-AR .container div img {
    width: 100%;
  }
  .vision-mission-history-AR .container div {
    flex-direction: column-reverse;
    padding: 0 2rem;
  }
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {
  background-color: #f3911e !important;
  opacity: 1 !important;
}

.btn-close:focus {
  box-shadow: 0 0 0 0.25rem #f3901e8f;
}

.btn-primary {
  background-color: #f3911e;
  border-color: #f3911e;
}

.btn-primary:hover {
  background-color: #f3911e;
  box-shadow: 0 0 0 0.25rem #f3901e8f;
  border-color: #f3911e;
}

.btn-primary:active {
  background-color: #f3911e;
  box-shadow: 0 0 0 0.25rem #f3901e8f;
  border-color: #f3911e;
}

.btn-primary:focus {
  background-color: #f3911e;
  box-shadow: 0 0 0 0.25rem #f3901e8f;
  border-color: rgb(243, 145, 30);
}

.Toastify__toast {
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 0.5%) !important;
}

.card-holder-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}

@media (max-width: 991px) {
  .card-holder {
    flex-direction: column !important;
  }

  .cart-hover:hover > div .card-holder div {
    width: 100%;
  }
  .card-holder > div:nth-child(3) {
    justify-content: center;
    margin: 1rem;
  }
  .card-holder div img {
    width: 100%;
    max-width: 100%;
    height: 140px;
    max-height: 141px;
    min-height: 139px;
    object-fit: cover;
  }
}

.card-holder-modal div {
  width: 25%;
}

.card-holder-modal div:nth-child(3) button {
  /* border: 1px solid gray !important; */
  padding: 0rem 0.5rem !important;
  font-size: 18px;
  background-color: #f3911e;
  color: white;
  box-shadow: 0px 0px 10px #f3911e inset;
  width: 28px !important;
  /* height: 30px !important; */
  line-height: 24px;
  border: 1px solid #bdbdbd;
}

.card-holder-modal div:nth-child(3) p {
  margin: 0 0.5rem;
}

.card-holder-modal div b {
  text-decoration: underline;
}

.card-holder-modal div:last-of-type {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row !important;
}

.card-holder-modal div:last-of-type h4 {
  margin-bottom: 0;
  font-size: 15px;
}

.card-holder-modal {
  color: gray;
}

.card-holder-modal div i {
  color: #eb8c1e;
}

@media (max-width: 551px) {
  .card-holder-modal div:nth-child(3) button {
    width: 30px !important;
    height: 30px !important;
  }
  .card-holder-modal > div {
    right: -134px;
  }
  .card-holder-modal div:nth-child(3) p {
    margin: 0.5rem !important;
  }
  .card-holder-modal > div {
    width: 363px;
  }
  .card-holder-modal > div img {
    width: 100% !important;
    object-fit: cover;
  }
}

.modal-dialog.modal-dialog-centered {
  max-width: 770px !important;
}

.card-holder-modal-AR {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  flex-direction: row-reverse;
  text-align: right;
}

.card-holder-modal-AR div {
  width: 25%;
}

.card-holder-modal-AR div:nth-child(3) button {
  /* border: 1px solid gray !important; */
  padding: 0rem 0.5rem !important;
  font-size: 20px;
  background-color: #f3911e;
  color: white;
  box-shadow: 0px 0px 10px #f3911e inset;
  width: 30px !important;
  /* height: 30px !important; */
  /* line-height: 10px; */
}

.card-holder-modal-AR div:nth-child(3) p {
  margin: 0 0.5rem;
}
.card-holder-modal-AR div h5 {
  text-align: right;
}

.card-holder-modal div b {
  text-decoration: underline;
}

.modal-header-AR {
  justify-content: right;
}

.card-holder-modal-AR div:last-of-type {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse !important;
}

.card-holder-modal-AR div:last-of-type h4 {
  margin-bottom: 0;
  font-size: 15px;
}

@media (max-width: 551px) {
  .card-holder-modal-AR div:nth-child(3) button {
    width: 30px !important;
    height: 30px !important;
  }
  .card-holder-modal-AR > div {
    right: -134px;
  }
  .card-holder-modal-AR div:nth-child(3) p {
    margin: 0 !important;
  }
  .card-holder-modal-AR > div {
    width: 363px;
  }
  .card-holder-modal-AR > div img {
    width: 100% !important;
    object-fit: cover;
  }
}

.product_modal_AR .modal-header {
  flex-direction: row-reverse !important;
}

.product_modal_AR .modal_Book_container {
  flex-direction: row-reverse;
  text-align: right;
}

.product_modal_AR .modal-header button {
  margin-left: 0;
}

.modal-footer {
  justify-content: flex-start;
}

@media (max-width: 600px) {
  .card-holder-modal {
    flex-direction: column;
  }
  .card-holder-modal > div {
    padding: 0 1rem;
  }
  .card-holder-modal div p {
    margin: 1rem !important;
  }
  .card-holder-modal div h5 {
    margin-top: 1rem;
  }
  .card-holder-modal div img {
    height: 220px !important;
  }
  .card-holder-modal div:last-of-type {
    justify-content: center;
  }
  .card-holder-modal div:last-of-type h4 {
    margin: 0 0.5rem;
  }
}
.fade.modal.show {
  padding-left: 0px !important;
}

div .slick-initialized:nth-child(2) .slick-list .slick-track {
  display: flex !important;
  width: 350px !important;
}
