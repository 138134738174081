.Book
    width: 300px !important
    margin: 1rem 0
    height: 384px
    .container
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        height: 100%
        text-align: center
        // border-radius: 15px 
        padding: 1rem !important
        box-shadow: 0px 0px 10px #4d4d4d2a
        border: 1px solid #4d4d4d41
        // cursor: pointer
        img
            max-width: 100%
            min-width: 100%
            min-height: 155px
            cursor: pointer
            object-fit: contain
            // border-radius: 7px 
        h2
            font-weight: 700
            color: gray
        .price
            display: flex
            p
                &:first-of-type
                    color: red
                    text-decoration: none !important
                &:last-of-type
                    color: gray
                    font-size: 13px
                    text-decoration: line-through
                    margin-left: 0.5rem
        .buttons
            display: flex
            justify-content: center
            width: 100%
            height: 127px
            button
                margin: 0.2rem
                padding: 0.5rem
                border: 0px solid gray
                border-radius: 5px
                outline: none
                color: #8f8f8f
                background-color: #f3901ed2
                color: white
                font-weight: 600
                width: 100%

.Book.row-one
    width: 100% !important
    height: auto !important
    .container
        text-align: left
        flex-direction: row !important
        justify-content: space-between
        img
            max-width: 100px !important
            min-width: 99px !important
            max-height: 70px !important
            min-height: auto
            @media ( max-width: 520px )
                max-width: 60px !important
        hr
            display: none !important
        .buttons
            width: 212px
            height: 50px !important
            button
                width: 65%
            @media ( max-width: 463px )
                button
                    width: 85%
                    &:nth-of-type(2)
                        font-size: 8px
                        padding: 0.5rem 0.2rem !important
        & > * 
            margin: 0 0.5rem
            @media ( max-width: 795px )
                font-size: 15px !important
            @media ( max-width: 772px )
                font-size: 11px !important
        .price
            flex-direction: column-reverse !important
    
    @media screen and ( max-width: 700px )
        .trapdoor 
            @media (max-width: 700px) 
                width: 60px
                margin-left: -22px
        
        .door 
            &:before 
                font-size: 10px
        
        
    


            
.Book.row-one-AR
    @extend .row-one
    .container
        flex-direction: row-reverse !important
