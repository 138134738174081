.main-nav
    background-color: #f3911e !important
    .container
        @media (max-width: 767px) 
            justify-content: center !important
    .nav-text
        p
            margin-bottom: 0 !important
            color: white
            @media (max-width: 767px) 
                font-size: 13px

.second-nav 
    border: 1px solid gray
    background-color: #c7c7c759 !important
    display: unset
    .second-nav-container
        // justify-content: flex-start !important
        // margin-top: 2rem
        @media (max-width: 991px)
            justify-content: space-between !important
            flex-direction: column !important
        @media ( max-width: 500px )
            margin-top: 1rem !important
        .navbar-brand
            margin-right: 2rem !important
        .sign-in
            display: flex
            align-items: center
            height: 57px
            @media screen and ( max-width: 991px )
                justify-content: center
            a
                button
                    border: none !important
                    background: transparent
                    padding: 0
                    font-size: 17px
                    i
                        font-size: 17px
            & > button
                    background: white !important
                    height: 46px !important
                    @media ( max-width: 500px )
                        height: auto !important
                        padding: 0.5rem 0.1rem
                    i
                        color: #f3911e
                        font-size: 15px
        .second-nav-form
            height: 57px
            background-color: white !important
            padding: 0.5rem
            border: 1px solid #bdbdbd
            width: 66%
            @media (max-width: 991px)
                width: 100%
                margin-top: 1rem
            a
                border: 1px solid #bdbdbd
                background: white
                color: gray
                height: 100%
                line-height: 20px
                padding: 0.5rem
                a
                    padding: 1rem 0.5rem
            input
                margin-right: 0 !important
                width: 76% !important
                @media ( max-width: 767px )
                    width: 100% !important
                &:focus
                    box-shadow: none !important
                &:hover
                    border: 1px solid #f3911e
                // @media (max-width: 1399px) 
                //     width: 57% !important
                // @media ( max-width: 1199px )
                //     width: 22% !important
        button
            border: 1px solid #bdbdbd
            padding: 0 1rem
            color: gray
            margin: 0rem 0.5rem
            font-size: 12px
    .nav-links-my
        width: 50%
        display: flex
        justify-content: flex-start !important
        margin-right: 0 !important
        margin-top: 2rem !important
        margin: auto !important
        justify-content: center
        // border-top: 2px solid #bdbdbd
        // border-bottom: 2px solid #bdbdbd
        position: sticky
        top: 0
        z-index: 100
        padding: 0rem 0
        background-color: white !important
        @media (max-width: 991px)
            margin-left: auto !important
            margin-right: auto !important
            width: 100%
            margin: 2rem 0
        @media (max-width: 500px)
            margin-top: 1rem !important
        a 
            font-weight: 600
            margin: 0 1rem 
            &:hover 
                color: #f3911e !important
        .dropdown-menu
            a
                margin: 0 !important

.main-nav-AR
    @extend .main-nav
    .container
        flex-direction: row-reverse
        @media ( max-width: 516px )
            flex-direction: column
        form
            flex-direction: row-reverse

.second-nav-AR
    @extend .second-nav
    .second-nav-container
        flex-direction: row-reverse !important
        justify-content: center !important
        .allCategories
            div
                left: -31px !important
        .navbar-brand
            margin-right: 0rem !important
            margin-left: 2rem
        .sign-in
            flex-direction: row-reverse
        .cart-hover
            div
                left: 0 !important
                @media ( max-width: 991px )
                    left: -100px !important
                    min-width: 0 !important
                div
                    flex-direction: row-reverse
                    div
                        &:last-of-type
                            // flex-direction: row-reverse !important
        .second-nav-form
            flex-direction: row-reverse !important
            input
                text-align: right !important
    .nav-links-my
        @extend .nav-links-my
        margin-left: 0 !important
        margin-right: auto !important
        @media ( max-width: 609px )
            width: 100% !important
        & > div
            @media screen and ( min-width: 991px )
                margin-left: auto !important
                margin-right: 0  !important
            flex-direction: row-reverse !important
        a
            @media ( max-width: 500px )
                margin: 0 0.4rem !important
                font-size: 15px !important
            @media ( max-width: 468px )
                margin: 0 0.4rem !important
                font-size: 14px !important
            @media ( max-width: 441px )
                margin: 0 0.4rem !important
                font-size: 13.5px !important
            @media ( max-width: 428px )
                margin: 0 0.4rem !important
                font-size: 12.5px !important
            @media ( max-width: 403px )
                margin: 0 0.4rem !important
                font-size: 12px !important
            @media ( max-width: 390px )
                margin: 0 0.4rem !important
                font-size: 11.5px !important
            @media ( max-width: 378px )
                margin: 0 0.4rem !important
                font-size: 11px !important
        .allproducts
            div
                left: -97px !important

.active 
    color: #f3911e !important

