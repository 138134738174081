.saleProduct
    margin-top: 3rem
    margin-bottom: 3rem
    .container-fluid
        display: flex
        & > div
        @media ( max-width: 989px )
            flex-direction: column !important
        .cale-num
            border: 1px solid gray
            width: 50%
            min-height: 500px
            position: relative
            @media ( max-width: 989px )
                width: 100%
            img
                width: 100%
                height: 100%
                position: absolute
                object-fit: cover
            & > div
                position: absolute
                height: 200px
                width: 250px
                bottom: 0
                left: 0
                clip-path: polygon(0% 100%, 0 24%, 55% 24%, 100% 100%)
                background-color: #ff5400
                color: white
                display: flex
                flex-direction: column
                justify-content: flex-end
                padding-left: 1rem
                div
                    display: flex
                    h1
                        font-size: 4rem !important
        .cale-img
            padding: 1rem
            background-color: #dddddd
            width: 50%
            @media ( max-width: 989px )
                width: 100%
            .single-product
                padding: 1rem
                display: flex
                @media ( max-width: 523px )
                    flex-direction: column !important
                .img-container
                    border: 3px solid #d30000dd 
                    padding: 0.5rem 3rem
                    margin-right: 1rem
                    position: relative
                    @media ( max-width: 523px )
                        display: flex
                        justify-content: center
                    @media ( max-width: 523px )
                        margin-bottom: 1rem
                    img
                        // padding: 1rem 2rem
                    h4
                        position: absolute
                        bottom: 0
                        left: 0
                        background: #d30000dd
                        color: white
                        font-size: 13px
                        margin-bottom: 0
                        padding: 0.5rem
                div
                    max-width: 300px

.saleProduct-AR
    @extend .saleProduct
    .container-fluid
        flex-direction: row-reverse !important
        @media ( max-width: 989px )
            flex-direction: column !important
        .cale-num
            & > div
                right: 0
                left: auto 
                transform: scaleX(-1)
                align-items: flex-start !important
                & > *
                    transform: scaleX(-1)
        .cale-img
            text-align: right
            .single-product
                // justify-content: flex-end !important
                flex-direction: row-reverse
                text-align: right
                .img-container
                    margin-right: 0
                    margin-left: 1rem 
                    text-align: right

