.form
    margin: 6rem 0
    .container
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        // background-color: #f3911e 
        form
            padding: 2rem
            border-radius: 15px 
            border-radius: 16px
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
            backdrop-filter: blur(4.4px)
            -webkit-backdrop-filter: blur(4.4px)
            border: 1px solid rgba(#f3911e, 0.41)
            .the-form
                display: flex
                flex-direction: column
                div
                    label
                        color: black
                    div
                        &::after
                            border-bottom: 2px solid #f3911e !important
                button
                    background-color: #f3911e !important
                    border: none
                    outline: none
                    box-shadow: none
                a
                    text-decoration: underline
                    cursor: pointer
                    text-align: right

.register-login
    color: #f3911e !important