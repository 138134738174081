.customerAccount
    .container
        .account-information
            margin-top: 5rem
            display: flex
            @media ( max-width: 1024px )
                flex-direction: column
            .account-image
                width: 25%
                height: 100%
                background-color: #eeeeee
                margin: 1rem 0.5rem
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                text-align: center
                padding: 1rem
                @media ( max-width: 1024px )
                    width: 100%
                @media ( max-width: 767px )
                    margin: 0 !important
                .customer-image
                    img
                        max-width: 200px
                        max-height: 200px 
                        object-fit: cover
                        background-color: white
                        border-radius: 50%
                        margin-bottom: 1rem 
                    & > a
                            color: #f3911e
                            text-decoration: none
                            font-weight: 600
                .account-links
                    button
                        width: 100%
                        border: none 
                        outline: none
                        margin: 0.5rem 0 
                        background: transparent
                        text-align: left !important
                        padding: 1rem 0.5rem
                        transition: all .5s ease-in-out
                        &:hover
                            background-color: white
                        a
                            text-decoration: none
                            font-size: 25px
                            font-weight: 600
                            color: #5f5f5f 
                            text-align: left !important
            .my-books-order
                width: 75%
                margin: 1rem 0.5rem
                @media ( max-width: 1024px )
                    width: 100%
                @media ( max-width: 767px )
                    margin: 1rem 0 !important
                & > h5
                        // margin: 2rem 0
                        background-color: #eeeeee
                        padding: 1rem
                        font-weight: 400
                .my-books
                    display: flex
                    flex-wrap: wrap
                    .book
                        margin: 1rem
                        display: flex
                        flex-direction: column
                        align-items: center
                        // justify-content: center
                        img
                            max-height: 200px
                        p
                            font-weight: 600
                            margin: 1rem 0
                .my-orders
                    background-color: #eeeeee
                    padding: 1rem
                    & > h5
                            background-color: #eeeeee
                            padding: 1rem
                            font-weight: 400
                    .order-date-code-total
                        display: flex
                        width: 90%
                        margin: auto
                        justify-content: space-between
                        border-top: 1px solid gray
                        border-bottom: 1px solid gray
                        padding: 1rem 2rem
                        @media ( max-width: 767px )
                            padding: 1rem 0rem
                            width: 100%
                        div
                            @media ( max-width: 1399px )
                                margin-left: 2rem
                            @media ( max-width: 1199px )
                                margin-left: .5rem
                            @media ( max-width: 767px )
                                margin-left: 0rem
                            &:last-of-type
                                width: 14%
                    .book-details
                        padding: 1rem 2rem
                        @media ( max-width: 767px )
                            padding: 1rem 0rem
                        display: flex
                        justify-content: space-between
                        align-items: center
                        text-align: center
                        width: 90%
                        margin: auto
                        transition: all .5s ease-in-out
                        @media ( max-width: 767px )
                            width: 100%
                        &:hover
                            background-color: white
                        img
                            max-height: 100px
                            @media ( max-width: 767px )
                                max-height: 50px
                        div
                            margin-left: 4rem
                            @media ( max-width: 1199px )
                                margin-left: 0 !important
                            @media ( max-width: 767px )
                                font-size: 11px
                            button
                                background-color: #f3911e
                                color: white
                                padding: .2rem 1.5rem
                                border: none
                                outline: none
                                border-radius: 5px
                                @media ( max-width: 767px )
                                    padding: .2rem .5rem
                                    font-size: 11px

.customerAccount-AR
    @extend .customerAccount
    .container
        .account-information
            flex-direction: row-reverse
            text-align: right
            @media ( max-width: 1024px )
                flex-direction: column !important
            .my-books-order
                .my-books
                    flex-direction: row-reverse
            .account-image
                .account-links
                    button
                        text-align: right !important
            .my-books-order
                .my-books
                .my-orders
                    .order-date-code-total
                        flex-direction: row-reverse !important
                    .book-details
                        flex-direction: row-reverse !important
                        div
                            margin-right: 4rem
                            margin-left: 0 !important
                            @media ( max-width: 1199px )
                                margin-right: 0rem !important